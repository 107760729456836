
import { onMounted, ref, defineComponent } from 'vue';
import axios from 'axios';

import utils from '@/utils';

export default defineComponent({
  setup() {
    const { formatDate } = utils();
    const lastUpdateMasterHash = ref('');
    const lastUpdateMasterTime = ref('');
    const lastDeployTime = ref('');

    onMounted(async () => {
      const masterRes = await axios({
        url: 'https://api.github.com/repos/jackey8616/Silverfish-Vue/commits/master',
        method: 'GET',
      });
      const ghRes = await axios({
        url: 'https://api.github.com/repos/jackey8616/Silverfish-Vue/deployments',
        method: 'GET',
      });
      lastUpdateMasterHash.value = masterRes.data.sha;
      lastUpdateMasterTime.value = formatDate(masterRes.data.commit.committer.date, true);
      lastDeployTime.value = formatDate(ghRes.data[0].created_at, true);
    });

    return { lastUpdateMasterHash, lastUpdateMasterTime, lastDeployTime };
  },
});
