
import {
  watch, defineComponent, computed, inject, ComputedRef,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useStore } from '@/store';

import authAPI from '@/api/auth';
import { useToast } from 'vue-toastification';

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const toast = useToast();
    const { authLogout, authStatus } = authAPI();
    const isLogin = inject<ComputedRef<boolean>>('isLogin')!;
    const session = inject<ComputedRef<string>>('session')!;
    const user = computed(() => store.getters['user/getUser']);

    const logout = async () => {
      await authLogout(session.value);
      store.dispatch('auth/logout');
      toast('登出成功');
      router.push({ path: '/' });
    };

    watch(() => route.path, async () => {
      if (isLogin.value === false || session.value === '') {
        return;
      }

      const statusData = await authStatus(session.value);
      if (statusData === false) {
        store.dispatch('auth/logout');
        toast.error('Session過期，請重新登入');
      }
    });

    return {
      store, isLogin, user, logout,
    };
  },
});
