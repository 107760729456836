
import { ref, onMounted, defineComponent } from 'vue';

export default defineComponent({
  props: ['size'],
  setup(props) {
    const scale = ref(1.0);

    onMounted(() => { scale.value = props.size.value / 100; });

    return { scale };
  },
});
