import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({'width': `${_ctx.size}px`, 'height': `${_ctx.size}px`,})
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle([{"width":"100%","height":"100%"}, {
        'width': `${_ctx.size}px !important`,
        'height': `${_ctx.size}px !important`,
        '-webkit-transform': `translate(-${_ctx.size}px, -${_ctx.size}px)`
          + `scale(1) translate(${_ctx.size}px, ${_ctx.size}px)`,
        'transform': `translate(-${_ctx.size}px, -${_ctx.size}px) scale(1) translate(${_ctx.size}px, ${_ctx.size}px)`,
      }]),
      class: "lds-eclipse"
    }, [
      _createElementVNode("div", {
        style: _normalizeStyle({
        'box-shadow': `0 ${2 * _ctx.scale}px 0 0 #0089d0`,
        'top': `${10 * _ctx.scale}px`,
        'left': `${10 * _ctx.scale}px`,
        'width': `${80 * _ctx.scale}px`,
        'height': `${80 * _ctx.scale}px`,
        '-webkit-transform-origin': `${40 * _ctx.scale}px ${41 * _ctx.scale}px`,
        'transform-origin': `${40 * _ctx.scale}px ${41 * _ctx.scale}px`,
      })
      }, null, 4)
    ], 4)
  ], 4))
}