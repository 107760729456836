
/* eslint-disable lines-between-class-members, class-methods-use-this */
import {
  nextTick, onMounted, provide, ref, defineComponent, computed,
  watch,
} from 'vue';
import ResizeObserver from 'resize-observer-polyfill';
import { useToast } from 'vue-toastification';
import { useGtag } from 'vue-gtag-next';
import { Md5 } from 'ts-md5';

import { useStore } from '@/store';
import UserAPI from '@/api/user';

import Navigator from '@/components/CNavigator.vue';
import Foot from '@/components/CFoot.vue';

export default defineComponent({
  components: { Navigator, Foot },
  setup() {
    const { set } = useGtag();
    const toast = useToast();
    const { latestBookmark } = UserAPI();
    const store = useStore();

    const height = ref(0);
    const withFootHeight = ref(0);
    const isLogin = computed(() => store.getters['user/isLogin']);
    const session = computed(() => store.getters['auth/getSession']);
    const fetchBookmark = () => {
      if (isLogin.value === true) {
        latestBookmark(session.value).then((data) => {
          store.commit('user/updateBookmark', data);
        }).catch((err) => {
          if (err.reason === 'SessionToken not exists') {
            store.dispatch('auth/logout');
            toast.error('不存在的Session，無法獲取書籤，請重新登入！');
          } else {
            console.error(err);
          }
        });
      }
    };

    provide('height', height);
    provide('withFootHeight', withFootHeight);
    provide('fetchBookmark', fetchBookmark);
    provide('isLogin', isLogin);
    provide('session', session);

    watch(() => store.getters['user/isLogin'], (newVal) => {
      if (newVal === true) {
        set({ user_id: `${Md5.hashStr(store.getters['user/getUser'].account)}` });
      } else {
        set({ user_id: '' });
      }
    });

    onMounted(() => {
      nextTick(() => {
        const ro = new ResizeObserver(() => {
          nextTick(() => {
            const el = document.getElementById('navigator');
            const footEl = document.getElementById('foot');
            if (el !== null) {
              height.value = window.innerHeight - el.clientHeight;
              withFootHeight.value = window.innerHeight - el.clientHeight - footEl!.clientHeight;
            }
          });
        });
        const el = document.getElementById('navigator');
        ro.observe(document.body);
        if (el !== null) {
          ro.observe(el);
        }
      });
    });
    return {};
  },
});
